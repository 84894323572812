<template>
    <section class="log-management padding_10_15">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 9px 0 7px; width: 250px" size="small"></el-input>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLogs" :columns="tableColumn" :query="formSearch" :height="830">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="data=row;dialogFormVisible=true;">详情</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
        <el-dialog title="详情" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="data">
                <el-form-item label="操作后" >
                    <el-input v-model="data.message" type="textarea" autocomplete="off" autosize readonly/>
                </el-form-item>
                <el-form-item label="操作前" >
                    <el-input v-model="data.updateTdMessage" type="textarea" autocomplete="off" autosize readonly/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import { tableColumn } from "./data";
    import { getLogPage } from "@/api/log";
    export default {
        name: "log-management",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {},
                data: {},
                dialogFormVisible: false,
            };
        },
        components:{
        },
        mounted() {

        },
        methods: {
            getLogs(params) {
                return getLogPage(params);
            },
            handleSearch() {
                this.$refs.orderTableRef.pNumber = 1;
                this.$refs.orderTableRef.getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            }
        }
    }
</script>

<style lang="scss" scoped>
    .log-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>