import {timeFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 60
    },
    {
        prop: "createTime",
        label: "时间",
        render: (h, {row}) => {
            const createTime = !row.createTime ? "" : timeFormat(new Date(row.createTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", createTime);
        },
    },
    {
        prop: "type",
        label: "操作类型",
    },
    {
        prop: "handleType",
        label: "记录类型",
    },
    {
        prop: "message",
        label: "操作后",
    },
    {
        prop: "updateTdMessage",
        label: "操作前",
    },
];

